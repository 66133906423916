import React, { useContext } from 'react'

import dayjs from 'dayjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import { fromEvent } from 'file-selector'
import { chain, capitalize, isEmpty, compact } from 'lodash'
import moment from 'moment'
import { useDropzone } from 'react-dropzone'

import { useGetDeal } from 'manager/hooks/api'
import {
  UPLOAD_ALLOWED_FILE_TYPES,
  MAX_FILE_UPLOAD_SIZE,
  NOTIFICATIONS,
  COLORS,
  isProductionEnv,
} from 'shared/config/constants'
import useModal from 'shared/hooks/useModal'
import type {
  Document,
  DocumentInApplication,
} from 'shared/interfaces/api/document'
import ApplicationContext from 'shared/pages/ApplicationSummary/applicationContext'
import { isManagerApp } from 'shared/utils/auth'
import { openNotification } from 'shared/utils/ui'

import FlexContainer from '../FlexContainer'
import Icon from '../Icon'
import Spacer from '../Spacer'
import { MediumTitle, RegularText } from '../Typography'

import { DeleteDocumentModal } from './DeleteDocumentModal'
import { DocumentLink } from './DocumentLink'
import { DocumentsUploadModal } from './DocumentsUploadModal'
import { LumenReady } from './LumenReady'
import {
  DeleteDocumentIcon,
  DocumentTable,
  DocumentTableHeader,
  UploadIconContainer,
  UploadZone,
} from './styled'
import {
  isFileEncrypted,
  isFileFormatValid,
  isFileSizeValid,
  isFileContentValid,
} from './utils'

const isDocument = (document: any): document is Document =>
  typeof document.createdAt !== 'undefined' &&
  typeof document.documentName !== 'undefined' &&
  typeof document.description !== 'undefined' &&
  typeof document.id !== 'undefined'

const AttachmentsRedesigned = () => {
  const { openModalDialog } = useModal()
  const {
    application,
    application: { snapshot },
  } = useContext(ApplicationContext)
  const { data: deal } = useGetDeal(application?.deal, {
    enabled: !isEmpty(application?.deal),
  })
  const requiredDocuments: DocumentInApplication[] = chain(application)
    .pick([
      'financialDocuments',
      'requiredDocuments',
      'unverifiedIncomeDocuments',
      'unverifiedBackgroundDocuments',
    ])
    .flatMap()
    .filter(({ isRequired }) => isRequired)
    .uniqBy('name')
    .sortBy([({ name }) => (name as string).toLowerCase()])
    .value()

  const validator = (
    file: File & { isContentTypeValid?: boolean; isEncrypted?: boolean },
  ) => {
    const { isContentTypeValid, isEncrypted, name, size } = file
    const valid =
      isFileFormatValid(name, UPLOAD_ALLOWED_FILE_TYPES) &&
      isFileSizeValid(MAX_FILE_UPLOAD_SIZE, size, name) &&
      !isEncrypted &&
      isContentTypeValid

    if (isEncrypted) {
      openNotification(
        `${name} is encrypted and cannot be uploaded. Remove the password security protection and try again.`,
        NOTIFICATIONS.error,
      )
    }

    if (!valid) return { code: '', message: '' }
    return null
  }

  const { getInputProps, getRootProps } = useDropzone({
    getFilesFromEvent: async (event) => {
      const files = await fromEvent(event)
      return Promise.all(
        files.map(async (file) =>
          Object.defineProperties(file, {
            isContentTypeValid: {
              value: await isFileContentValid(file, UPLOAD_ALLOWED_FILE_TYPES),
            },
            isEncrypted: {
              value: await isFileEncrypted(file),
            },
          }),
        ),
      )
    },
    onDropAccepted: (acceptedFiles) => {
      openModalDialog(DocumentsUploadModal, {
        acceptedFiles,
        applicationId: application?.id ?? '',
        applicantVrn: application?.applicantVrn ?? '',
        personVrn: application?.personVrn ?? '',
        requiredDocuments,
      })
    },
    validator,
  })
  const uploadedDocuments = chain(snapshot)
    .pick(
      compact([isManagerApp() && 'applicantDocuments', 'originalDocuments']),
    )
    .values()
    .flatMap()
    .filter(({ tags }) => {
      const isRestricted = tags?.includes('restricted')
      return (
        !isRestricted || (isRestricted && deal?.canViewRestrictedAttachments)
      )
    })
    .sortBy([({ description }) => description.toLowerCase()])
    .value()

  const documents = chain(requiredDocuments)
    .reduce<(Document | (DocumentInApplication & { index: number }))[]>(
      (acc, curr) => {
        for (let i = curr.documents.length; i < curr.requiredQty; i++) {
          acc.push({ ...curr, index: i })
        }
        return acc
      },
      [...uploadedDocuments],
    )
    .value()

  return (
    <>
      {application?.dealDecisionIsMade ? (
        <FlexContainer
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          gap="8px"
        >
          <Icon.InfoBlueIcon height={32} width={32} />
          <MediumTitle>
            The page is now view only. A decision on the deal was already made.
          </MediumTitle>
        </FlexContainer>
      ) : (
        <UploadZone
          alignItems="center"
          flexDirection="column"
          gap="4px"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <UploadIconContainer
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Icon.UploadNewIcon height={28} width={28} />
          </UploadIconContainer>
          <RegularText
            underline
            style={{ color: COLORS.violetTheme.lightViolet2, fontSize: '17px' }}
          >
            Tap or click to upload
          </RegularText>
          <Spacer size={Spacer.SIZES.xs} />
          <RegularText extraStrong style={{ fontSize: '10px' }}>
            PNG, JPEG, PDF (Max: 5 MB)
          </RegularText>
          <RegularText
            style={{ fontSize: '10px', textAlign: 'center', width: '258px' }}
          >
            Avoid combined pdfs. Each paystub or document should be uploaded
            individually.
          </RegularText>
        </UploadZone>
      )}
      <Spacer size={Spacer.SIZES.default} />
      <DocumentTable
        columns={[
          {
            dataIndex: 'filename',
            render: (_, { id, documentName, name }) =>
              name ? (
                <RegularText italic light>
                  {capitalize(name)}
                </RegularText>
              ) : (
                <DocumentLink id={id} documentName={documentName} />
              ),
            sorter: (a, b) => {
              if (!isDocument(a) || !isDocument(b)) return 0
              return a.documentName.localeCompare(b.documentName)
            },
            title: <DocumentTableHeader strong>File Name</DocumentTableHeader>,
          },
          {
            dataIndex: 'description',
            render: (_, { description }) =>
              description && (
                <RegularText>
                  {chain(description).replace(/_/g, ' ').startCase().value()}
                </RegularText>
              ),
            sorter: (a, b) => {
              if (!isDocument(a) || !isDocument(b)) return 0
              return a.description.localeCompare(b.description)
            },
            title: (
              <DocumentTableHeader strong>
                User Indicated Type
              </DocumentTableHeader>
            ),
          },
          ...(isManagerApp && deal?.canViewOcrResults
            ? [
                {
                  dataIndex: 'lumenReady',
                  render: (_, { id, type }) => {
                    if (id || type) {
                      return [
                        'w2',
                        '1099',
                        'paystub',
                        'bank-statement',
                      ].includes(type) ? (
                        <LumenReady applicationId={application?.id} id={id} />
                      ) : (
                        <RegularText>Unsupported</RegularText>
                      )
                    }
                    return null
                  },
                  title: (
                    <FlexContainer
                      alignItems="center"
                      flexDirection="row"
                      gap="4px"
                    >
                      <DocumentTableHeader strong>
                        {deal?.lumenEnabled ? 'Lumen Ready' : 'Fraud Risk'}
                      </DocumentTableHeader>
                      <Icon.BlueQuestionMark
                        height={14}
                        cursor="pointer"
                        width={14}
                        onClick={() =>
                          window?.Intercom?.(
                            'showArticle',
                            isProductionEnv ? 9621394 : 9641772,
                          )
                        }
                      />
                    </FlexContainer>
                  ),
                },
              ]
            : []),
          {
            align: 'center',
            dataIndex: 'uploadDate',
            render: (_, { createdAt }) =>
              createdAt && (
                <RegularText>
                  {dayjs(createdAt).format('MMM. DD, YYYY')}
                </RegularText>
              ),
            sorter: (a, b) => {
              if (!isDocument(a) || !isDocument(b)) return 0
              return moment(a.createdAt).diff(moment(b.createdAt))
            },
            title: (
              <DocumentTableHeader strong>Uploaded Date</DocumentTableHeader>
            ),
          },
          {
            dataIndex: 'uploadedBy',
            render: (_, { id, tags, uploadedBy }) =>
              id && (
                <RegularText>
                  {uploadedBy && uploadedBy?.name
                    ? uploadedBy?.name
                    : tags?.includes('manager-upload')
                    ? 'Manager'
                    : 'Applicant'}
                </RegularText>
              ),
            sorter: (a, b) => {
              if (!isDocument(a) || !isDocument(b)) return 0
              const aDefaultName = a.tags?.includes('manager-upload')
                ? 'Manager'
                : 'Applicant'
              const bDefaultName = b.tags?.includes('manager-upload')
                ? 'Manager'
                : 'Applicant'
              const x = a.uploadedBy?.name ?? aDefaultName
              const y = b.uploadedBy?.name ?? bDefaultName
              return x.localeCompare(y)
            },
            title: (
              <DocumentTableHeader strong>Uploaded By</DocumentTableHeader>
            ),
          },
          ...(application?.dealDecisionIsMade || isEmpty(uploadedDocuments)
            ? []
            : [
                {
                  dataIndex: 'delete',
                  render: (_, { id, type }) =>
                    id &&
                    !['agreement', 'contract'].includes(type) && (
                      <DeleteDocumentIcon
                        height={16}
                        onClick={() =>
                          openModalDialog(DeleteDocumentModal, {
                            applicationId: application?.id,
                            documentId: id,
                          })
                        }
                      />
                    ),
                  title: '',
                  width: '5%',
                },
              ]),
        ]}
        dataSource={[...documents]}
        noBorderBottom
        noClickableRows
        pagination={false}
        rowKey={({ id, name, index }) => id ?? `${name}_${index}`}
        size="small"
      />
    </>
  )
}

export default AttachmentsRedesigned
