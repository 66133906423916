import * as Yup from "yup";

import { REGEX } from "shared/config/constants";

// Case indesitive alphabet, spaces, single apostrophe and dash.
// Also special modifiers such as accents, ç, ü, ø, â, ñ, etc.

const LONGEST_VALID_NUMBER = 9223372036854775807;

export const validFirstName = Yup.string()
  .nullable()
  .min(2, "First name must have at least 2 characters")
  .max(50, "First name cannot have more than 50 characters")
  .matches(
    REGEX.name,
    "First name must contain only english alphabet letters, whitespaces and apostrophes"
  );

export const validMiddleName = Yup.string()
  .nullable()
  .max(50, "Middle name cannot have more than 50 characters")
  .matches(
    REGEX.name,
    "Middle name must contain only english alphabet letters, whitespaces and apostrophes"
  );

export const validLastName = Yup.string()
  .nullable()
  .min(2, "Last name must have at least 2 characters")
  .max(50, "Last name cannot have more than 50 characters")
  .matches(
    REGEX.name,
    "Last name must contain only english alphabet letters, whitespaces and apostrophes"
  );

export const validEmail = Yup.string()
  .nullable()
  .email("Invalid email format.")
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
    "Invalid email format. Please enter a valid email address."
  );

export const validPhone = Yup.string()
  .nullable()
  .matches(REGEX.phoneNumber, "Phone number is invalid.");

export const validAddress = Yup.string()
  .nullable()
  .max(100)
  .matches(
    REGEX.oneLetterOneNumber,
    "The Address field must contain at least one number and a letter"
  )
  .matches(
    REGEX.lettersNumbersSpecialChars,
    "The Address field can only contain letters and the allowed special characters: ['.', ' ', '-']"
  );

export const validMoney = Yup.number()
  .max(LONGEST_VALID_NUMBER, "Must be a valid money value")
  .nullable();

export const validUrl = Yup.string()
  .nullable()
  .url("Please enter a valid url.");
